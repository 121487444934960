import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import { supabase } from '../plugins/supabase';
import styles from '../modules/EditRecipe.module.css';
import placeholderImage from '../assets/landscape-placeholder.svg';

const backendUrl = process.env.REACT_APP_BACKEND_URL;

const ingredientUnits = [
    'GRAM',
    'KILOGRAM',
    'MILLILITER',
    'LITER',
    'STYKK',
    'MATSKEI',
    'TESKEI',
    'SPISESKEI',
    'KLYPE',
];

const stores = [
    'SPAR',
    'Meny',
    'KIWI',
    'Joker',
    'Coop'
];

const EditRecipe = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [token, setToken] = useState(null);
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [tags, setTags] = useState('');
  const [instructions, setInstructions] = useState('');
  const [ingredients, setIngredients] = useState([]);
  const [recipeImage, setRecipeImage] = useState(null);
  const [existingImageUrl, setExistingImageUrl] = useState(null);
  const [popupVisible, setPopupVisible] = useState(false);
  const [popupIngredient, setPopupIngredient] = useState({ name: '', amount: '', unit: '' });
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [typingTimeout, setTypingTimeout] = useState(null);
  const [selectedStores, setSelectedStores] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [isPublic, setIsPublic] = useState(true);
  const [loading, setLoading] = useState(true);
  const [editingIngredientIndex, setEditingIngredientIndex] = useState(null);

  useEffect(() => {
    const getUser = async () => {
      const { data: { session } } = await supabase.auth.getSession();
      if (session) {
        setToken(session.access_token);
      } else {
        navigate('/login'); 
      }
    };
    getUser();
  }, [navigate]);

  useEffect(() => {
    const fetchRecipe = async () => {
      try {
        const response = await axios.get(`${backendUrl}/api/recipes/${id}?includeProductInfo=true`, {
          headers: token ? { Authorization: `Bearer ${token}` } : {},
        });

        const {
          name,
          description,
          tags,
          instructions,
          ingredients,
          imageUrl,
          isPublic,
        } = response.data.recipe;

        setName(name);
        setDescription(description);
        setTags(tags.join(', '));
        setInstructions(instructions);
        setExistingImageUrl(imageUrl);
        setIsPublic(isPublic);

        const ingredientsWithProducts = ingredients.map((ingredient) => {
          const matchedProduct = response.data.productInfo.find(product => product.ean === ingredient.ean);
          return {
            ...ingredient,
            products: matchedProduct ? [matchedProduct] : [],
          };
        });

        setIngredients(ingredientsWithProducts);
      } catch (error) {
        console.error('Error fetching recipe:', error);
      } finally {
        setLoading(false);
      }
    };

    if (token) {
      fetchRecipe();
    }
  }, [id, token]);

  const handleRecipeImageChange = (event) => {
    setRecipeImage(event.target.files[0]);
  };

  const handleSubmitIngredient = () => {
    if (popupIngredient.name && popupIngredient.amount && popupIngredient.unit && selectedProduct) {
      const newIngredient = {
        name: popupIngredient.name,
        amount: popupIngredient.amount,
        unit: popupIngredient.unit,
        ean: selectedProduct.ean,
        storeCode: selectedProduct.store?.code,
        products: [selectedProduct],
      };

      if (editingIngredientIndex !== null) {
        setIngredients(prevIngredients => {
          const updatedIngredients = [...prevIngredients];
          updatedIngredients[editingIngredientIndex] = newIngredient;
          return updatedIngredients;
        });
        setEditingIngredientIndex(null);
      } else {
        setIngredients(prevIngredients => [...prevIngredients, newIngredient]);
      }

      setPopupVisible(false);
    } else {
      alert('Please fill in all ingredient details and select a product.');
    }
  };

  const handleUpdate = async (event) => {
    event.preventDefault();

    const filteredIngredients = ingredients.map(ingredient => ({
      ean: ingredient.ean,
      storeCode: ingredient.storeCode,
      amount: ingredient.amount,
      name: ingredient.name,
      image: ingredient.products.length > 0 ? ingredient.products[0].image : '',
      unit: ingredient.unit,  
    }));

    if (filteredIngredients.length === 0) {
      alert('Please add at least one valid ingredient.');
      return;
    }

    const updatedRecipe = {
      name,
      description,
      tags: tags.split(',').map(tag => tag.trim()), 
      instructions,
      ingredients: filteredIngredients, 
      imageUrl: existingImageUrl, 
      isPublic,
      currentPrice: 0.0,  
      storedPrice: 0.0,  
      priceLastUpdated: new Date().toISOString(),  
    };

    try {
      if (recipeImage) {
        const imageFormData = new FormData();
        imageFormData.append('file', recipeImage);

        const imageUploadResponse = await axios.post(`${backendUrl}/api/recipes/upload-image`, imageFormData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${token}`,
          },
        });

        const imageUrl = imageUploadResponse.data;
        updatedRecipe.imageUrl = imageUrl; 
      }

      await axios.patch(`${backendUrl}/api/recipes/${id}`, updatedRecipe, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
      });

      alert('Recipe updated successfully');
      navigate(`/recipes/${id}`);
    } catch (error) {
      console.error('Error during update process:', error);
    }
};



  const fetchProducts = useCallback(async () => {
    try {
        const params = {
            query: searchQuery,
            page: currentPage,
            pageSize: 12,
            selectedStores: selectedStores.length > 0 ? selectedStores.join(',') : null,
        };

        const response = await axios.get(`${backendUrl}/api/products/search`, { params });
        const { products, totalPages } = response.data;

        setFilteredProducts(products);
        setTotalPages(totalPages);
    } catch (err) {
        console.error('Error fetching products:', err);
    }
}, [searchQuery, selectedStores, currentPage]);

useEffect(() => {
    fetchProducts();
}, [fetchProducts]);

  const handleSearchChange = useCallback(
    (e) => {
        const query = e.target.value;
        setSearchQuery(query);

        if (typingTimeout) {
            clearTimeout(typingTimeout);
        }

        const timeoutId = setTimeout(() => {
            fetchProducts();
        }, 700);

        setTypingTimeout(timeoutId);
    },
    [typingTimeout, fetchProducts]
);

  const handleAddIngredient = () => {
    setPopupIngredient({ name: '', amount: '', unit: '', products: [] });
    setSelectedProduct(null);
    setPopupVisible(true);
};

    const handlePopupIngredientChange = (e) => {
        const { name, value } = e.target;
        setPopupIngredient(prev => ({
            ...prev,
            [name]: value,
        }));
    };

    const handlePopupProductClick = (product) => {
        setSelectedProduct(product);
    };

    const handleStoreChange = useCallback(
        (store) => {
            if (selectedStores.includes(store)) {
                const updatedStores = selectedStores.filter(s => s !== store);
                setSelectedStores(updatedStores);  
            } else {
                setSelectedStores([...selectedStores, store]); 
            }
            setCurrentPage(1);  
        },
        [selectedStores]
    );
    
      const handlePageChange = useCallback(
          (pageNumber) => {
              if (pageNumber >= 1 && pageNumber <= totalPages) {
                  setCurrentPage(pageNumber);
                  fetchProducts(); 
              }
          },
          [totalPages, fetchProducts]
      );

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className={styles["recipe-container"]}>
      <div className={styles["recipe-header"]}>
        <h1 className={styles["recipe-title"]}>Rediger oppskrift</h1>
        <div style={{ display: "flex", alignItems: "center" }}>
          <span className={styles["toggle-label"]}>{isPublic ? "Offentlig" : "Privat"}</span>
          <label className={styles["toggle-switch"]}>
            <input 
              type="checkbox" 
              checked={isPublic} 
              onChange={(e) => setIsPublic(e.target.checked)} 
            />
            <span className={`${styles["slider"]} ${styles["round"]}`}></span>
          </label>
        </div>
      </div>
      <form onSubmit={handleUpdate} className={styles["form-container"]}>
        <input
          type="text"
          placeholder="Tittel"
          value={name}
          onChange={(e) => setName(e.target.value)}
          required
          className={styles["form-input"]}
        />
        <textarea
          placeholder="Kort beskrivelse"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          required
          className={styles["form-textarea"]}
        />
        <input
          type="text"
          placeholder="Knagger (separert med komma)"
          value={tags}
          onChange={(e) => setTags(e.target.value)}
          required
          className={styles["form-input"]}
        />
        <textarea
          placeholder="Instruksjon"
          value={instructions}
          onChange={(e) => setInstructions(e.target.value)}
          required
          className={styles["form-textarea"]}
        />
        <div>
          <label htmlFor="recipe-image" className={styles["form-label"]}>
            {existingImageUrl ? "Oppdater bilde (valgfritt)" : "Last opp ett bilde"}
          </label>
          <input
            type="file"
            id="recipe-image"
            name="recipeImage"
            accept="image/*"
            onChange={handleRecipeImageChange}
            className={styles["form-input"]}
          />
          {existingImageUrl && !recipeImage && (
            <img
              src={existingImageUrl}
              alt="Existing Recipe"
              className={styles["existing-recipe-image"]}
            />
          )}
          {recipeImage && (
            <img
              src={URL.createObjectURL(recipeImage)}
              alt="New Recipe"
              className={styles["existing-recipe-image"]}
            />
          )}
        </div>

        <h3 className={styles["ingredients-title"]}>Ingredienser</h3>
        <div className={styles["ingredients-grid"]}>
          {ingredients.map((ingredient, index) => (
            <div key={index} className={styles["ingredient-card"]}>
              <div className={styles["ingredient-card-content"]}>
                <div className={styles["ingredient-info-left"]}>
                  <span className={styles["ingredient-text"]}>{ingredient.name}</span>
                  <span className={styles["product-name"]}>
                    {ingredient.amount} {ingredient.unit}
                  </span>
                  {ingredient.products.length > 0 && (
                    <>
                      <span className={styles["product-name"]}>{ingredient.products[0].name}</span>
                      <span className={styles["product-price"]}>
                        {ingredient.products[0].current_price?.toFixed(2)} kr
                      </span>
                    </>
                  )}
                </div>
                {ingredient.products[0]?.store?.logo && (
                  <img
                    src={ingredient.products[0].store.logo}
                    alt={ingredient.products[0].store.name}
                    className={styles["ingredient-store-logo"]}
                  />
                )}
                <div className={styles["ingredient-image"]}>
                  <img
                    src={
                      ingredient.products.length > 0
                        ? ingredient.products[0].image
                        : placeholderImage
                    }
                    alt={ingredient.name || "placeholder"}
                    className={styles["ingredient-product-image"]}
                  />
                </div>
              </div>
            </div>
          ))}
          <div className={`${styles["ingredient-card"]} ${styles["add-ingredient-card"]}`} onClick={handleAddIngredient}>
            <div className={styles["add-ingredient-icon"]}>+</div>
            <div className={styles["add-ingredient-text"]}>Legg til en ingrediens</div>
          </div>
        </div>

        <button type="submit" className={`${styles["form-button"]} ${styles["bg-primary"]}`}>
          Oppdater oppskriften
        </button>
      </form>

      {popupVisible && (
        <div className={styles["popup-container"]}>
          <button className={styles["close-button"]} onClick={() => setPopupVisible(false)}>
            &times;
          </button>
          <div className={styles["popup-content"]}>
            <h3 className={styles["popup-title"]}>Legg til en ingrediens</h3>

            <input
              type="text"
              name="name"
              placeholder="Ingrediens"
              value={popupIngredient.name}
              onChange={handlePopupIngredientChange}
              className={styles["form-input"]}
            />
            <div className={styles["amount-unit-container"]}>
              <input
                type="text"
                name="amount"
                placeholder="Mengde"
                value={popupIngredient.amount}
                onChange={handlePopupIngredientChange}
                className={`${styles["form-input"]} ${styles["amount-input"]}`}
              />
              <select
                name="unit"
                value={popupIngredient.unit}
                onChange={handlePopupIngredientChange}
                className={`${styles["form-select"]} ${styles["unit-select"]}`}
              >
                <option value="">Velg enhet</option>
                {ingredientUnits.map((unit) => (
                  <option key={unit} value={unit}>
                    {unit}
                  </option>
                ))}
              </select>
            </div>
            <input
              type="text"
              placeholder="Søk på ett produkt... (navn, merke, kategori, butikk, eks: 'spaghetti first price')"
              value={searchQuery}
              onChange={handleSearchChange}
              className={styles["search-input"]}
            />

            <div className={styles["store-filter"]}>
              {stores.map((store) => (
                <label key={store} className={styles["store-checkbox"]}>
                  <input
                    type="checkbox"
                    value={store}
                    checked={selectedStores.includes(store)}
                    onChange={() => {
                      handleStoreChange(store);
                      handleSearchChange();
                    }}
                  />
                  {store}
                </label>
              ))}
            </div>

            <div className={styles["popup-grid"]}>
              {filteredProducts.map((product) => (
                <div
                  key={`${product.ean}_${product.store?.code}`}
                  className={`${styles["popup-product"]} ${
                    selectedProduct?.ean === product.ean && selectedProduct?.store?.code === product.store?.code
                      ? styles["selected-product"]
                      : ""
                  }`}
                  onClick={() => handlePopupProductClick(product)}
                >
                  <div className={styles["popup-product-title"]}>
                    {product.name}
                  </div>

                  <div className={styles["popup-product-bottom"]}>
                    <div className={styles["popup-product-price"]}>
                      {product.current_price?.toFixed(2)} kr
                    </div>
                    {product.store && product.store.logo && (
                      <img
                        src={product.store.logo}
                        alt={product.store.name}
                        className={styles["popup-store-logo"]}
                      />
                    )}
                    <img
                      src={product.image}
                      alt={product.name}
                      className={styles["popup-product-image"]}
                    />
                  </div>
                </div>
              ))}
            </div>

            <div className={styles["pagination-controls"]}>
              <button
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
                className={styles["pagination-button"]}
              >
                Forrige
              </button>
              <span className={styles["current-page-info"]}>
                {currentPage} of {totalPages}
              </span>
              <button
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={currentPage === totalPages}
                className={styles["pagination-button"]}
              >
                Neste
              </button>
            </div>
          </div>
          <button className={styles["done-button"]} onClick={handleSubmitIngredient}>
            Ferdig
          </button>
        </div>
      )}
    </div>
  );
};

export default EditRecipe;
