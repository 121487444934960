import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { supabase } from '../plugins/supabase';
import styles from '../modules/CreateRecipe.module.css';
import placeholderImage from '../assets/landscape-placeholder.svg';

const backendUrl = process.env.REACT_APP_BACKEND_URL;

const ingredientUnits = [
    'GRAM',
    'KILOGRAM',
    'MILLILITER',
    'LITER',
    'STYKK',
    'MATSKEI',
    'TESKEI',
    'SPISESKEI',
    'KLYPE',
];

const stores = [
    'SPAR',
    'Meny',
    'KIWI',
    'Joker',
    'Coop'
];

const CreateRecipe = () => {

  const navigate = useNavigate();

  const [userId, setUserId] = useState(null);
  const [token, setToken] = useState(null);
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [tags, setTags] = useState('');
  const [instructions, setInstructions] = useState('');
  const [ingredients, setIngredients] = useState([]);
  const [recipeImage, setRecipeImage] = useState(null);
  const [popupVisible, setPopupVisible] = useState(false);
  const [popupIngredient, setPopupIngredient] = useState({ name: '', amount: '', unit: '' });
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [typingTimeout, setTypingTimeout] = useState(null);
  const [selectedStores, setSelectedStores] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [isPublic, setIsPublic] = useState(true);

  useEffect(() => {
      const getUser = async () => {
          const { data: { session } } = await supabase.auth.getSession();
          if (session) {
              setUserId(session.user.id);
              setToken(session.access_token);
          }
      };
      getUser();
  }, []);

  const fetchProducts = useCallback(async () => {
      try {
          const params = {
              query: searchQuery,
              page: currentPage,
              pageSize: 12,
              selectedStores: selectedStores.length > 0 ? selectedStores.join(',') : null,
          };

          const response = await axios.get(`${backendUrl}/api/products/search`, { params });
          const { products, totalPages } = response.data;

          setFilteredProducts(products);
          setTotalPages(totalPages);
      } catch (err) {
          console.error('Error fetching products:', err);
      }
  }, [searchQuery, selectedStores, currentPage]);

  useEffect(() => {
      fetchProducts();
  }, [fetchProducts]);

  const handleSearchChange = useCallback(
      (e) => {
          const query = e.target.value;
          setSearchQuery(query);

          if (typingTimeout) {
              clearTimeout(typingTimeout);
          }

          const timeoutId = setTimeout(() => {
              fetchProducts();
          }, 700);

          setTypingTimeout(timeoutId);
      },
      [typingTimeout, fetchProducts]
  );

  const handleAddIngredient = () => {
      setPopupIngredient({ name: '', amount: '', unit: '', products: [] });
      setSelectedProduct(null);
      setPopupVisible(true);
  };

  const handlePopupIngredientChange = (e) => {
      const { name, value } = e.target;
      setPopupIngredient(prev => ({
          ...prev,
          [name]: value,
      }));
  };

  const handlePopupProductClick = (product) => {
      setSelectedProduct(product);
  };

  const handleSubmitIngredient = () => {
      if (popupIngredient.name && popupIngredient.amount && popupIngredient.unit && selectedProduct) {
          const newIngredient = {
              name: popupIngredient.name,
              amount: popupIngredient.amount,
              unit: popupIngredient.unit,
              ean: selectedProduct.ean,
              storeCode: selectedProduct.store?.code,
              products: [selectedProduct],
          };
          setIngredients(prevIngredients => [...prevIngredients, newIngredient]);
          setPopupVisible(false);
      } else {
          alert('Please fill in all ingredient details and select a product.');
      }
  };

  const handleRecipeImageChange = (event) => {
      setRecipeImage(event.target.files[0]);
  };

  const handleSubmit = async (event) => {
      event.preventDefault();

      const filteredIngredients = ingredients.map(ingredient => ({
          name: ingredient.name,
          amount: parseFloat(ingredient.amount),
          unit: ingredient.unit,
          ean: ingredient.ean,
          storeCode: ingredient.storeCode,
      }));

      if (filteredIngredients.length === 0) {
          alert('Please add at least one valid ingredient.');
          return;
      }

      const recipe = {
          name,
          description,
          tags: tags.split(',').map(tag => tag.trim()),
          instructions,
          ingredients: filteredIngredients,
          userId,
          isPublic,
      };

      let imageUrl = null;

      try {
          if (recipeImage) {
              const imageFormData = new FormData();
              imageFormData.append('file', recipeImage);

              const imageUploadResponse = await axios.post(`${backendUrl}/api/recipes/upload-image`, imageFormData, {
                  headers: {
                      'Content-Type': 'multipart/form-data',
                      'Authorization': `Bearer ${token}`,
                  },
              });

              imageUrl = imageUploadResponse.data;
              recipe.imageUrl = imageUrl;
          }

          const recipeFormData = new FormData();
          recipeFormData.append('createRecipeDto', new Blob([JSON.stringify(recipe)], { type: 'application/json' }));

          const response = await axios.post(`${backendUrl}/api/recipes/create`, recipeFormData, {
              headers: {
                  'Content-Type': 'multipart/form-data',
                  'Authorization': `Bearer ${token}`,
              },
          });

          console.log('Recipe created successfully:', response.data);
          navigate('/');
      } catch (error) {
          console.error('There was an error during the process:', error);
      }
  };

  const handleStoreChange = useCallback(
    (store) => {
        if (selectedStores.includes(store)) {
            const updatedStores = selectedStores.filter(s => s !== store);
            setSelectedStores(updatedStores);  
        } else {
            setSelectedStores([...selectedStores, store]); 
        }
        setCurrentPage(1);  
    },
    [selectedStores]
);

  const handlePageChange = useCallback(
      (pageNumber) => {
          if (pageNumber >= 1 && pageNumber <= totalPages) {
              setCurrentPage(pageNumber);
              fetchProducts(); 
          }
      },
      [totalPages, fetchProducts]
  );
      
  return (
    <div className={styles["recipe-container"]}>
      <div className={styles["recipe-header"]}>
        <h1 className={styles["recipe-title"]}>Lag en oppskrift</h1>
        <div style={{ display: "flex", alignItems: "center" }}>
          <span className={styles["toggle-label"]}>{isPublic ? "Offentlig" : "Privat"}</span>
          <label className={styles["toggle-switch"]}>
            <input 
              type="checkbox" 
              checked={isPublic} 
              onChange={(e) => setIsPublic(e.target.checked)} 
            />
            <span className={`${styles["slider"]} ${styles["round"]}`}></span>
          </label>
        </div>
      </div>
      <form onSubmit={handleSubmit} className={styles["form-container"]}>
        <input
          type="text"
          placeholder="Tittel"
          value={name}
          onChange={(e) => setName(e.target.value)}
          required
          className={styles["form-input"]}
        />
        <textarea
          placeholder="Kort beskrivelse"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          required
          className={styles["form-textarea"]}
        />
        <input
          type="text"
          placeholder="Knagger (separert med komma)"
          value={tags}
          onChange={(e) => setTags(e.target.value)}
          required
          className={styles["form-input"]}
        />
        <textarea
          placeholder="Instruksjon"
          value={instructions}
          onChange={(e) => setInstructions(e.target.value)}
          required
          className={styles["form-textarea"]}
        />
        <div>
          <label htmlFor="recipe-image" className={styles["form-label"]}>
            Last opp ett bilde
          </label>
          <input
            type="file"
            id="recipe-image"
            name="recipeImage"
            accept="image/*"
            onChange={handleRecipeImageChange}
            className={styles["form-input"]}
          />
        </div>

        <h3 className={styles["ingredients-title"]}>Ingredienser</h3>
        <div className={styles["ingredients-grid"]}>
          {ingredients.map((ingredient, index) => (
            <div key={index} className={styles["ingredient-card"]}>
              <div className={styles["ingredient-card-content"]}>
                <div className={styles["ingredient-info-left"]}>
                  <span className={styles["ingredient-text"]}>{ingredient.name}</span>
                  <span className={styles["product-name"]}>
                    {ingredient.amount} {ingredient.unit}
                  </span>
                  {ingredient.products.length > 0 && (
                    <>
                      <span className={styles["product-name"]}>
                        {ingredient.products[0].name}
                      </span>
                      <span className={styles["product-price"]}>
                        {ingredient.products[0].current_price?.toFixed(2)} kr
                      </span>
                    </>
                  )}
                </div>
                {ingredient.products[0]?.store?.logo && (
                  <img
                    src={ingredient.products[0].store.logo}
                    alt={ingredient.products[0].store.name}
                    className={styles["ingredient-store-logo"]}
                  />
                )}
                <div className={styles["ingredient-image"]}>
                  <img
                    src={
                      ingredient.products.length > 0
                        ? ingredient.products[0].image
                        : placeholderImage
                    }
                    alt={ingredient.name || "placeholder"}
                    className={styles["ingredient-product-image"]}
                  />
                </div>
              </div>
            </div>
          ))}
          <div className={`${styles["ingredient-card"]} ${styles["add-ingredient-card"]}`} onClick={handleAddIngredient}>
            <div className={styles["add-ingredient-icon"]}>+</div>
            <div className={styles["add-ingredient-text"]}>Legg til en ingrediens</div>
          </div>
        </div>

        <button type="submit" className={`${styles["form-button"]} ${styles["bg-primary"]}`}>
          Lag oppskriften
        </button>
      </form>

      {popupVisible && (
        <div className={styles["popup-container"]}>
          <button className={styles["close-button"]} onClick={() => setPopupVisible(false)}>
            &times;
          </button>
          <div className={styles["popup-content"]}>
            <h3 className={styles["popup-title"]}>Legg til en ingrediens</h3>

            <input
              type="text"
              name="name"
              placeholder="Ingrediens"
              value={popupIngredient.name}
              onChange={handlePopupIngredientChange}
              className={styles["form-input"]}
            />
            <div className={styles["amount-unit-container"]}>
              <input
                type="text"
                name="amount"
                placeholder="Mengde"
                value={popupIngredient.amount}
                onChange={handlePopupIngredientChange}
                className={`${styles["form-input"]} ${styles["amount-input"]}`}
              />
              <select
                name="unit"
                value={popupIngredient.unit}
                onChange={handlePopupIngredientChange}
                className={`${styles["form-select"]} ${styles["unit-select"]}`}
              >
                <option value="">Velg enhet</option>
                {ingredientUnits.map((unit) => (
                  <option key={unit} value={unit}>
                    {unit}
                  </option>
                ))}
              </select>
            </div>
            <input
              type="text"
              placeholder="Søk på ett produkt... (navn, merke, kategori, butikk, eks: 'spaghetti first price')"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              className={styles["search-input"]}
            />

            {/* Stores Filter */}
            <div className={styles["store-filter"]}>
              {stores.map((store) => (
                <label key={store} className={styles["store-checkbox"]}>
                  <input
                    type="checkbox"
                    value={store}
                    checked={selectedStores.includes(store)}
                    onChange={() => {
                      handleStoreChange(store);
                      handleSearchChange();
                    }}
                  />
                  {store}
                </label>
              ))}
            </div>

            <div className={styles["popup-grid"]}>
            {filteredProducts.map((product) => (
              <div
                key={`${product.ean}_${product.store?.code}`}
                className={`${styles["popup-product"]} ${
                  selectedProduct?.ean === product.ean && selectedProduct?.store?.code === product.store?.code
                    ? styles["selected-product"]
                    : ""
                }`}
                onClick={() => handlePopupProductClick(product)}
              >
                  {/* Top section for product title */}
                  <div className={styles["popup-product-title"]}>
                    {product.name}
                  </div>

                  {/* Bottom section for price, store logo, and product image */}
                  <div className={styles["popup-product-bottom"]}>
                    {/* Price */}
                    <div className={styles["popup-product-price"]}>
                      {product.current_price?.toFixed(2)} kr
                    </div>

                    {/* Store logo */}
                    {product.store && product.store.logo && (
                      <img
                        src={product.store.logo}
                        alt={product.store.name}
                        className={styles["popup-store-logo"]}
                      />
                    )}

                    {/* Product image */}
                    <img
                      src={product.image}
                      alt={product.name}
                      className={styles["popup-product-image"]}
                    />
                  </div>
                </div>
              ))}
            </div>



            {/* Pagination Controls */}
            <div className={styles["pagination-controls"]}>
              {/* Previous Button */}
              <button
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
                className={styles["pagination-button"]}
              >
                Forrige
              </button>

              {/* Current Page Info */}
              <span className={styles["current-page-info"]}>
                {currentPage} of {totalPages}
              </span>

              {/* Next Button */}
              <button
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={currentPage === totalPages}
                className={styles["pagination-button"]}
              >
                Neste
              </button>
            </div>
          </div>
          <button className={styles["done-button"]} onClick={handleSubmitIngredient}>
            Ferdig
          </button>
        </div>
      )}
    </div>
  );
};

export default CreateRecipe;
