import React from 'react';
import styles from '../modules/Footer.module.css';

const Footer = () => {
  return (
    <footer className={styles.footer}>
      <div className={styles.container}>
        {/* Contact Information */}
        <div className={styles.contact}>
          <h3>Kontakt informasjon</h3>
          <p><a href="mailto:vindenez@gmail.com">vindenez@gmail.com</a></p>
        </div>

        {/* Message */}
        <div className={styles.message}>
          <p>Produktinformasjon hentet fra <a href="https://kassal.app" target="_blank" rel="noopener noreferrer">https://kassal.app</a> og oppdateres daglig</p>
        </div>

        {/* Social Media Links */}
        <div className={styles.social}>
          <h3>Følg meg</h3>
          <ul className={styles.socialLinks}>
            <li>
              <a href="https://www.linkedin.com/in/p%C3%A5l-hegrenes-vindenes-89b0a123b/" target="_blank" rel="noopener noreferrer">
                LinkedIn
              </a>
            </li>
          </ul>
        </div>

      </div>
    </footer>
  );
};

export default Footer;
