import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { supabase } from './plugins/supabase';
import RecipeList from './components/RecipeList';
import CreateRecipe from './components/CreateRecipe';
import Signup from './components/Signup';
import Login from './components/Login';
import Header from './components/Header';
import Account from './components/Account';
import RecipeView from './components/RecipeView';
import LandingPage from './components/LandingPage';
import ProductsView from './components/ProductsView';
import RecipeSaved from './components/RecipeSaved';
import EditRecipe from './components/EditRecipe';
import RecipeListUser from './components/RecipeListUser';
import Footer from './components/Footer'; 

const PrivateRoute = ({ element: Element }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const checkUser = async () => {
      const { data } = await supabase.auth.getSession();
      setIsAuthenticated(!!data.session); 
      setIsLoading(false); 
    };
    checkUser();
  }, []);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return isAuthenticated ? <Element /> : <Navigate to="/login" />;
};

function App() {
  return (
    <Router>
      <Header /> 
      <div className="App">
        <main>
          <Routes>
            {/* Landing Page */}
            <Route path="/" element={<LandingPage />} />

            {/* Private Routes (Authenticated users only) */}
            <Route path="/create" element={<PrivateRoute element={CreateRecipe} />} />
            <Route path="/account" element={<PrivateRoute element={Account} />} />
            <Route path="/recipes/saved" element={<PrivateRoute element={RecipeSaved} />} />
            <Route path="/recipes/edit/:id" element={<PrivateRoute element={EditRecipe} />} />
            <Route path="/recipes/user/" element={<PrivateRoute element={RecipeListUser} />} />

            {/* Public Routes */}
            <Route path="/recipes" element={<RecipeList />} />
            <Route path="/signup" element={<Signup />} />
            <Route path="/products" element={<ProductsView />} />
            <Route path="/login" element={<Login />} />
            <Route path="/recipes/:id" element={<RecipeView />} />
          </Routes>
        </main>
      </div>
      <Footer /> 
    </Router>
  );
}

export default App;
