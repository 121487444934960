import { createClient } from '@supabase/supabase-js';

const supabaseKey = process.env.REACT_APP_SUPABASE_API_KEY;
const supabaseUrl = process.env.REACT_APP_SUPABASE_URL;

if (!supabaseUrl || !supabaseKey) {
  throw new Error('Missing Supabase URL or Supabase Key');
}

export const supabase = createClient(supabaseUrl, supabaseKey, {
  auth: {
    autoRefreshToken: true,
    persistSession: true,
  },
});

export const signOut = async () => {
  await supabase.auth.signOut({ scope: 'local' });
};
