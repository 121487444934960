import React, { useEffect, useState, useCallback, useRef } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import styles from '../modules/RecipeList.module.css';  
import { supabase } from '../plugins/supabase';
import placeholderImage from '../assets/landscape-placeholder.svg';


const backendUrl = process.env.REACT_APP_BACKEND_URL;



const RecipeList = () => {

  const [recipes, setRecipes] = useState([]);
  const [fetchedImages, setFetchedImages] = useState({});
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [error, setError] = useState(null);
  const [query, setQuery] = useState('');
  const [minPrice, setMinPrice] = useState('');
  const [maxPrice, setMaxPrice] = useState('');
  const [sort, setSort] = useState('date-desc'); 
  const [tokenLoaded, setTokenLoaded] = useState(false); 

  const tokenRef = useRef(null);
  const queryRef = useRef(query);
  const minPriceRef = useRef(minPrice);
  const maxPriceRef = useRef(maxPrice);
  const sortRef = useRef(sort);

  useEffect(() => {
    const getUser = async () => {
      const { data: { session } } = await supabase.auth.getSession();
      if (session) {
        tokenRef.current = session.access_token;
      }
      setTokenLoaded(true); 
    };
    getUser();
  }, []);

  const fetchRecipes = useCallback(async (page = 0) => {
    if (!tokenLoaded) return;
  
    const cacheKey = `recipes_${queryRef.current}_${minPriceRef.current}_${maxPriceRef.current}_${sortRef.current}_page${page}`;
    const cachedData = sessionStorage.getItem(cacheKey);
  
    if (cachedData) {
      const { content, number, totalPages, cacheTime } = JSON.parse(cachedData);
  
      if (Date.now() - cacheTime < 60) { 
        setRecipes(content);
        setCurrentPage(number);
        setTotalPages(totalPages);
  
        content.forEach((recipe) => {
          if (recipe.imageUrl) {
            fetchRecipeImage(recipe.id, recipe.imageUrl);
          }
        });
        return;
      } else {
        sessionStorage.removeItem(cacheKey);
      }
    }
  
    try {
      const [sortBy, sortDirection] = sortRef.current ? sortRef.current.split('-') : ['date', 'asc'];
      const response = await axios.get(`${backendUrl}/api/recipes/search`, {
        params: {
          query: queryRef.current,
          page,
          pageSize: 20,
          minPrice: minPriceRef.current || null,
          maxPrice: maxPriceRef.current || null,
          sortBy: sortBy || 'date',
          sortDirection: sortDirection || 'asc',
        },
      });
  
      const data = response.data;
      setRecipes(data.content);
      setCurrentPage(data.number);
      setTotalPages(data.totalPages);
      setError(null);
  
      data.content.forEach((recipe) => {
        if (recipe.imageUrl) {
          fetchRecipeImage(recipe.id, recipe.imageUrl);
        }
      });
  
      sessionStorage.setItem(
        cacheKey,
        JSON.stringify({
          content: data.content,
          number: data.number,
          totalPages: data.totalPages,
          cacheTime: Date.now(), 
        })
      );
    } catch (err) {
      setError('Det oppstod ett problem ved henting av oppskrifter. Last inn siden på nytt og prøv igjen.');
      console.error('Error fetching recipes:', err);
    }
  }, [tokenLoaded]);
  

  const fetchRecipeImage = async (recipeId, imageUrl) => {
    try {
      const headers = tokenRef.current ? { Authorization: `Bearer ${tokenRef.current}` } : {};
      const imageResponse = await axios.get(imageUrl, { headers, responseType: 'blob' });
      const imageObjectUrl = URL.createObjectURL(imageResponse.data);

      setFetchedImages((prev) => ({
        ...prev,
        [recipeId]: imageObjectUrl,
      }));
    } catch (err) {
      console.error('Error fetching image:', err);
    }
  };

  useEffect(() => {
    if (tokenLoaded) {
      fetchRecipes(0);
    }
  }, [fetchRecipes, tokenLoaded]);

  const handlePageChange = (newPage) => {
    fetchRecipes(newPage);
  };

  useEffect(() => {
    queryRef.current = query;
  }, [query]);
  
  useEffect(() => {
    minPriceRef.current = minPrice;
  }, [minPrice]);
  
  useEffect(() => {
    maxPriceRef.current = maxPrice;
  }, [maxPrice]);
  
  useEffect(() => {
    sortRef.current = sort;
  }, [sort]);

  return (
    <div className={styles['recipe-list-container']}>
      <div className={styles['search-filters']}>
        <input
          type="text"
          placeholder="Søk..."
          value={query}
          onChange={(e) => setQuery(e.target.value)}
        />
        <input
          type="number"
          placeholder="Min Pris"
          value={minPrice}
          onChange={(e) => setMinPrice(e.target.value)}
        />
        <input
          type="number"
          placeholder="Max Pris"
          value={maxPrice}
          onChange={(e) => setMaxPrice(e.target.value)}
        />
        <select value={sort} onChange={(e) => setSort(e.target.value)}>
          <option value="price-asc">Pris (lav til høy)</option>
          <option value="price-desc">Pris (høy til lav)</option>
          <option value="date-asc">Eldste først</option>
          <option value="date-desc">Nyeste først</option>
        </select>
        <button onClick={() => fetchRecipes(0)}>Søk</button>
      </div>
      {error && <div className={`${styles['error-message']} ${styles['subtle-error']}`}>{error}</div>}

      <div className={styles['recipe-wrapper']}>
        {recipes.length === 0 ? (
          <p className={styles['no-recipes']}>Ingen oppskrifter funnet..</p>
        ) : (
          <div className={styles['recipe-grid']}>
            {recipes.map((recipe) => (
              <Link to={`/recipes/${recipe.id}`} key={recipe.id} className={styles['recipe-clist-card-link']}>
                <div className={styles['recipe-card']}>
                  <div className={styles['recipe-details']}>
                    <h2 className={styles['recipe-title']}>{recipe.name}</h2>
                    <p className={styles['recipe-price']}>{Math.round(recipe.storedPrice)} kr</p>
                  </div>
                  <div className={styles['recipe-image-wrapper']}>
                    <img
                      src={fetchedImages[recipe.id] || placeholderImage}
                      alt={recipe.name}
                      className={styles['recipe-image']}
                    />
                  </div>
                </div>
              </Link>
            ))}
          </div>
        )}

        <div className={styles['pagination']}>
          {Array.from({ length: totalPages }, (_, index) => (
            <button
              key={index}
              onClick={() => handlePageChange(index)}
              disabled={index === currentPage}
              className={`${styles['pagination-button']} ${index === currentPage ? styles['active'] : ''}`}
            >
              {index + 1}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};

export default RecipeList;
