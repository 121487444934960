import React, { useEffect, useState, useCallback } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import styles from '../modules/RecipeList.module.css';  
import { supabase } from '../plugins/supabase';
import placeholderImage from '../assets/landscape-placeholder.svg';

const backendUrl = process.env.REACT_APP_BACKEND_URL;

const RecipeListUser = () => {
  const [recipes, setRecipes] = useState([]);
  const [fetchedImages, setFetchedImages] = useState({});
  const [error, setError] = useState(null);
  const [tokenLoaded, setTokenLoaded] = useState(false); 
  const [token, setToken] = useState(null);

  useEffect(() => {
    const getUser = async () => {
      const { data: { session } } = await supabase.auth.getSession();
      if (session) {
        setToken(session.access_token);
      }
      setTokenLoaded(true); 
    };
    getUser();
  }, []);

  const fetchRecipeImage = useCallback(async (recipeId, imageUrl) => {
    try {
      const headers = token ? { Authorization: `Bearer ${token}` } : {};
      const imageResponse = await axios.get(imageUrl, { headers, responseType: 'blob' });
      const imageObjectUrl = URL.createObjectURL(imageResponse.data);

      setFetchedImages((prev) => ({
        ...prev,
        [recipeId]: imageObjectUrl,
      }));
    } catch (err) {
      console.error('Error fetching image:', err);
    }
  }, [token]);

  const fetchUserRecipes = useCallback(async () => {
    if (!tokenLoaded || !token) return;

    try {
      const response = await axios.get(`${backendUrl}/api/recipes/user-recipes`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          page: 0,
          size: 100,
        },
      });

      const data = response.data;
      setRecipes(data.content);
      setError(null);

      data.content.forEach((recipe) => {
        if (recipe.imageUrl) {
          fetchRecipeImage(recipe.id, recipe.imageUrl);
        }
      });
    } catch (err) {
      setError('Ingen oppskrifter funnet.');
      console.error('Error fetching user recipes:', err);
    }
  }, [tokenLoaded, token, fetchRecipeImage]);

  useEffect(() => {
    fetchUserRecipes();
  }, [fetchUserRecipes]);

  if (tokenLoaded && !token) {
    return <div>Vennligst logg inn for å se dine oppskrifter.</div>;
  }

  if (error) {
    return <div className={`${styles['error-message']} ${styles['subtle-error']}`}>{error}</div>;
  }

  return (
    <div className={styles['recipe-list-container']}>
      <h1 className={styles['title']}>Dine Oppskrifter</h1>
      {recipes.length === 0 ? (
        <p className={styles['no-recipes']}>Du har ingen oppskrifter.</p>
      ) : (
        <div className={styles['recipe-grid']}>
          {recipes.map((recipe) => (
            <Link to={`/recipes/${recipe.id}`} key={recipe.id} className={styles['recipe-card-link']}>
              <div className={styles['recipe-card']}>
                <div className={styles['recipe-image-wrapper']}>
                  <img
                    src={fetchedImages[recipe.id] || placeholderImage}
                    alt={recipe.name}
                    className={styles['recipe-image']}
                  />
                </div>
                <div className={styles['recipe-details']}>
                  <h2 className={styles['recipe-title']}>{recipe.name}</h2>
                  <p className={styles['recipe-price']}>{recipe.storedPrice} kr</p>
                </div>
              </div>
            </Link>
          ))}
        </div>
      )}
    </div>
  );
};

export default RecipeListUser;
