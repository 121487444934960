import React, { useEffect, useState } from 'react';
import { supabase } from '../plugins/supabase';

const Account = () => {
  const [email, setEmail] = useState('');

  useEffect(() => {
    const fetchUser = async () => {
      const { data: { session } } = await supabase.auth.getSession();
      if (session) {
        setEmail(session.user.email);
      }
    };
    fetchUser();
  }, []);

  const handleLogout = async () => {
    const { error } = await supabase.auth.signOut();
    if (error) console.log('Error logging out:', error);
    window.location.href = '/';
  };

  return (
    <div className="max-w-xl mx-auto mt-10 p-6 bg-white">
      <h1 className="text-3xl font-bold text-primary mb-4">Bruker</h1>
      {email ? (
        <>
          <p className="text-gray-700 mb-6">Logget på som: <span className="font-semibold">{email}</span></p>
          <button 
            onClick={handleLogout} 
            className="bg-secondary text-white px-4 py-2 hover:bg-secondary-dark transition"
          >
            Logg ut
          </button>
        </>
      ) : (
        <p className="text-primary">Laster...</p>
      )}
    </div>
  );
};

export default Account;
