import React, { useEffect, useState, useCallback, useRef } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import styles from '../modules/RecipeList.module.css';  
import { supabase } from '../plugins/supabase';
import placeholderImage from '../assets/landscape-placeholder.svg';

const backendUrl = process.env.REACT_APP_BACKEND_URL;

const RecipeSaved = () => {
  const [recipes, setRecipes] = useState([]);
  const [fetchedImages, setFetchedImages] = useState({});
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [error, setError] = useState(null);
  const [tokenLoaded, setTokenLoaded] = useState(false); 

  const tokenRef = useRef(null);

  useEffect(() => {
    const getUser = async () => {
      const { data: { session } } = await supabase.auth.getSession();
      if (session) {
        tokenRef.current = session.access_token;
      }
      setTokenLoaded(true); 
    };
    getUser();
  }, []);

  const fetchRecipeImage = useCallback(async (recipeId, imageUrl) => {
    try {
      const headers = tokenRef.current ? { Authorization: `Bearer ${tokenRef.current}` } : {};
      const imageResponse = await axios.get(imageUrl, { headers, responseType: 'blob' });
      const imageObjectUrl = URL.createObjectURL(imageResponse.data);

      setFetchedImages((prev) => ({
        ...prev,
        [recipeId]: imageObjectUrl,
      }));
    } catch (err) {
      console.error('Error fetching image:', err);
    }
  }, []);

  const fetchSavedRecipes = useCallback(async (page = 0) => {
    if (!tokenLoaded || !tokenRef.current) return;

    try {
      const response = await axios.get(`${backendUrl}/api/recipes/save/list`, {
        headers: {
          Authorization: `Bearer ${tokenRef.current}`,
        },
        params: {
          page,
          size: 20, 
        },
      });

      const data = response.data;
      setRecipes(data.content);
      setCurrentPage(data.number);
      setTotalPages(data.totalPages);
      setError(null);

      data.content.forEach((savedRecipe) => {
        const recipe = savedRecipe.recipe;
        if (recipe.imageUrl) {
          fetchRecipeImage(recipe.id, recipe.imageUrl);
        }
      });
    } catch (err) {
      setError('Ingen oppskrifter funnet.');
      console.error('Error fetching saved recipes:', err);
    }
  }, [tokenLoaded, fetchRecipeImage]);

  useEffect(() => {
    fetchSavedRecipes(0);
  }, [fetchSavedRecipes]);

  const handlePageChange = (newPage) => {
    fetchSavedRecipes(newPage);
  };

  if (tokenLoaded && !tokenRef.current) {
    return <div>Vennligst logg inn for å se dine lagrede oppskrifter.</div>;
  }

  if (error) {
    return <div className={`${styles['error-message']} ${styles['subtle-error']}`}>{error}</div>;
  }

  return (
    <div className={styles['recipe-list-container']}>
      <h1 className={styles['title']}>Lagrede Oppskrifter</h1>
      <div className={styles['recipe-wrapper']}>
        {recipes.length === 0 ? (
          <p className={styles['no-recipes']}>Ingen oppskrifter funnet..</p>
        ) : (
          <div className={styles['recipe-grid']}>
            {recipes.map((savedRecipe) => {
              const recipe = savedRecipe.recipe;
              return (
                <Link to={`/recipes/${recipe.id}`} key={recipe.id} className={styles['recipe-clist-card-link']}>
                  <div className={styles['recipe-card']}>
                    <div className={styles['recipe-details']}>
                      <h2 className={styles['recipe-title']}>{recipe.name}</h2>
                      <p className={styles['recipe-price']}>{Math.round(recipe.storedPrice)} kr</p>
                    </div>
                    <div className={styles['recipe-image-wrapper']}>
                      <img
                        src={fetchedImages[recipe.id] || placeholderImage}
                        alt={recipe.name}
                        className={styles['recipe-image']}
                      />
                    </div>
                  </div>
                </Link>
              );
            })}
          </div>
        )}

        <div className={styles['pagination']}>
          {Array.from({ length: totalPages }, (_, index) => (
            <button
              key={index}
              onClick={() => handlePageChange(index)}
              disabled={index === currentPage}
              className={`${styles['pagination-button']} ${index === currentPage ? styles['active'] : ''}`}
            >
              {index + 1}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};

export default RecipeSaved;
