import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { supabase } from '../plugins/supabase';
import axios from 'axios';

const backendUrl = process.env.REACT_APP_BACKEND_URL;

const Signup = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [username, setUsername] = useState('');
  const [emailSent, setEmailSent] = useState(false);
  const [error, setError] = useState('');
  const [isPending, setIsPending] = useState(false);
  const navigate = useNavigate();

  const signUp = async (e) => {
    e.preventDefault();
    setError('');
    setIsPending(true);

    const { data, error: signUpError } = await supabase.auth.signUp({
      email,
      password,
    });

    if (signUpError) {
      setError(signUpError.message);
      setIsPending(false);
      return;
    }

    if (data.user) {
      setEmailSent(true);

      try {
        await axios.post(`${backendUrl}/api/users`, {
          supabaseUserId: data.user.id,
          email: data.user.email,
          name: username,
        });
      } catch (err) {
        setError('Error saving user to database');
        setIsPending(false);
      }
    }

    setIsPending(false);
  };

  const goToHome = async () => {
    try {
      const { error: signInError } = await supabase.auth.signInWithPassword({
        email,
        password,
      });
      
      if (signInError) {
        setError('Could not automatically log in. Please try logging in manually.');
      } else {
        navigate('/');
      }
    } catch (err) {
      setError('An error occurred while signing in.');
    }
  };

  return (
    <div className="max-w-md mx-auto mt-10 p-6 bg-white">
      {emailSent ? (
        <div>
          <h1 className="text-2xl font-bold text-primary mb-6">Bekreft emailen din</h1>
          <p className="text-gray-700">
            Vi har sendt en epost til <span className="font-semibold">{email}</span>. Vennligst trykk på linken i eposten for å bekrefte emailen din.
          </p>
          <button
            onClick={goToHome}
            className="mt-4 w-full bg-primary text-white py-2 hover:bg-primary-dark transition-colors duration-300"
          >
            Har du bekreftet?
          </button>
        </div>
      ) : (
        <div>
          <h1 className="text-2xl font-bold text-primary mb-6">Kom i gang</h1>
          <p className="text-gray-700 mb-4">Opprett en bruker</p>
          <form onSubmit={signUp}>
            {error && <div className="text-red-500 mb-4">{error}</div>}
            <div className="mb-4">
              <label className="block text-gray-700">Email</label>
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                className="w-full px-4 py-2 border border-gray-300 focus:outline-none focus:ring-2 focus:ring-primary"
              />
            </div>
            <div className="mb-4">
              <label className="block text-gray-700">Passord</label>
              <input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
                className="w-full px-4 py-2 border border-gray-300 focus:outline-none focus:ring-2 focus:ring-primary"
              />
            </div>
            <div className="mb-6">
              <label className="block text-gray-700">Brukernavn</label>
              <input
                type="text"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                required
                className="w-full px-4 py-2 border border-gray-300 focus:outline-none focus:ring-2 focus:ring-primary"
              />
            </div>
            <button
              type="submit"
              disabled={isPending}
              className="w-full bg-primary text-white py-2 hover:bg-primary-dark transition-colors duration-300"
            >
              {isPending ? 'Signing Up...' : 'Sign Up'}
            </button>
          </form>
          <div className="mt-4 text-center">
            <p className="text-gray-700">Har du allerede en bruker?</p>
            <Link to="/login" className="text-primary hover:underline">
              Logg på nå
            </Link>
          </div>
        </div>
      )}
    </div>
  );
};

export default Signup;
