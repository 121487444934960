import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { supabase } from '../plugins/supabase';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [isPending, setIsPending] = useState(false);
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    setError('');
    setIsPending(true);

    const { data, error: signInError } = await supabase.auth.signInWithPassword({
      email,
      password,
    });

    if (signInError) {
      setError(signInError.message);
    } else {
      if (data.session) {
        navigate('/');
      }
    }

    setIsPending(false);
  };

  return (
    <div className="max-w-md mx-auto mt-10 p-6 bg-white">
      <h1 className="text-2xl font-bold text-primary mb-6">Logg på</h1>
      <form onSubmit={handleLogin}>
        {error && <div className="text-red-500 mb-4">{error}</div>}
        <div className="mb-4">
          <label className="block text-gray-700">Email</label>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            className="w-full px-4 py-2 border border-gray-300 focus:outline-none focus:ring-2 focus:ring-primary"
          />
        </div>
        <div className="mb-6">
          <label className="block text-gray-700">Passord</label>
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
            className="w-full px-4 py-2 border border-gray-300 focus:outline-none focus:ring-2 focus:ring-primary"
          />
        </div>
        <button
          type="submit"
          disabled={isPending}
          className="w-full bg-primary text-white py-2 hover:bg-primary-dark transition-colors duration-300"
        >
          {isPending ? 'Logging In...' : 'Login'}
        </button>
      </form>
      <div className="mt-4 text-center">
        <p className="text-gray-700">Har du ikke en bruker?</p>
        <Link to="/signup" className="text-primary hover:underline">
          Opprett bruker her
        </Link>
      </div>
    </div>
  );
};

export default Login;
