import React, { useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import { supabase } from '../plugins/supabase';
import styles from '../modules/Header.module.css';

const Header = () => {
  const [loggedIn, setLoggedIn] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);

  useEffect(() => {
    const checkUser = async () => {
      const { data: { session } } = await supabase.auth.getSession();
      setLoggedIn(!!session);
    };
    checkUser();

    const { data: authListener } = supabase.auth.onAuthStateChange((event, session) => {
      setLoggedIn(!!session);
    });

    return () => {
      authListener.subscription.unsubscribe();
    };
  }, []);

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  return (
    <header className={styles.bgPrimary}>
      <div className={styles.headerContainer}>
        <div className={styles.logo}>
          <Link to="/" className={styles.headerLink}>
            <span className={styles.logoText}>Matplaner</span>
          </Link>
        </div>
        <nav className={styles.nav}>
        <Link to="/recipes" className={styles.headerLink}><span>Oppskrifter</span></Link>
          <Link to="/create" className={styles.headerLink}><span>Lag oppskrift</span></Link>
          <Link to="/products" className={styles.headerLink}><span>Produkter</span></Link>

        </nav>
        <div className={styles.divider}> </div>

        <div className={styles.loginButton} ref={dropdownRef}>
          {loggedIn ? (
            <div className={styles.dropdownContainer}>
              <button
                className={styles.headerLink}
                onClick={toggleDropdown}
              >
                <span>Bruker ▾</span>
              </button>
              {dropdownOpen && (
                <div className={styles.dropdownMenu}>
                  <Link to="/account" className={styles.dropdownItem}>Min Konto</Link>
                  <Link to="/recipes/saved" className={styles.dropdownItem}>Lagrede oppskrifter</Link>
                  <Link to="/recipes/user" className={styles.dropdownItem}>Dine oppskrifter</Link>
                  <button
                    className={styles.dropdownItem}
                    onClick={async () => {
                      await supabase.auth.signOut();
                      setDropdownOpen(false);
                    }}
                  >
                    Logg ut
                  </button>
                </div>
              )}
            </div>
          ) : (
            <Link to="/login" className={styles.headerLink}><span>Logg på</span></Link>
          )}
        </div>
      </div>
    </header>
  );
};

export default Header;
