import React from 'react';
import styles from '../modules/LandingPage.module.css';
import RecipeList from './RecipeList'; 

const LandingPage = () => {
  return (
    <div className={styles.landingPageContainer}>
      {/* Large Text at the Top */}
      <div className={styles.topSection}>
        <h1 className={styles.largeText}>Velkommen til Matplaner</h1>
        <p className={styles.subtitleText}>Finn oppskrifter og produkter med oppdaterte priser!</p>
      </div>

      {/* RecipeList Component */}
      <div className={styles.recipeListSection}>
        <RecipeList />
      </div>
    </div>
  );
};

export default LandingPage;
