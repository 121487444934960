import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import styles from '../modules/ProductsView.module.css';

const backendUrl = process.env.REACT_APP_BACKEND_URL;

const stores = ['SPAR', 'Meny', 'KIWI', 'Joker', 'Coop', 'Bunnpris'];

const ProductsView = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedStores, setSelectedStores] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const fetchProducts = useCallback(async () => {
    try {
      const params = {
        query: searchQuery,
        page: currentPage,
        pageSize: 12,
        selectedStores: selectedStores.length > 0 ? selectedStores.join(',') : null,
      };

      const response = await axios.get(`${backendUrl}/api/products/search`, { params });
      const { products, totalPages } = response.data;

      setFilteredProducts(products);
      setTotalPages(totalPages);
    } catch (err) {
      console.error('Error fetching products:', err);
    }
  }, [searchQuery, selectedStores, currentPage]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      fetchProducts();
    }, 700);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [fetchProducts]);

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
    setCurrentPage(1); 
  };

  const handleStoreChange = (store) => {
    if (selectedStores.includes(store)) {
      const updatedStores = selectedStores.filter((s) => s !== store);
      setSelectedStores(updatedStores);
    } else {
      setSelectedStores([...selectedStores, store]);
    }
    setCurrentPage(1); 
  };

  const handleProductClick = (product) => {
    const productKey = `${product.ean}_${product.store?.code}`;
    const isSelected = selectedProducts.some(
      (p) => `${p.ean}_${p.store?.code}` === productKey
    );

    if (isSelected) {
      setSelectedProducts((prev) =>
        prev.filter((p) => `${p.ean}_${p.store?.code}` !== productKey)
      );
    } else {
      setSelectedProducts((prev) => [...prev, product]);
    }
  };

  const handlePageChange = (pageNumber) => {
    if (pageNumber >= 1 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
    }
  };

  const totalPrice = selectedProducts.reduce(
    (sum, product) => sum + (product.current_price || 0),
    0
  );

  return (
    <div className={styles['view-products-container']}>
      <h1 className={styles['title']}>Produkter</h1>

      {/* Search and Filters */}
      <div className={styles['search-filters']}>
        <input
          type="text"
          placeholder="Søk på ett produkt... (navn, merke, kategori, butikk)"
          value={searchQuery}
          onChange={handleSearchChange}
          className={styles['search-input']}
        />

        <div className={styles['store-filter']}>
          {stores.map((store) => (
            <label key={store} className={styles['store-checkbox']}>
              <input
                type="checkbox"
                value={store}
                checked={selectedStores.includes(store)}
                onChange={() => handleStoreChange(store)}
              />
              {store}
            </label>
          ))}
        </div>
      </div>

      {/* Products Grid */}
      <div className={styles['products-grid']}>
        {filteredProducts.map((product, index) => {
          const productKey = `${product.ean}_${product.store?.code}`;
          const isSelected = selectedProducts.some(
            (p) => `${p.ean}_${p.store?.code}` === productKey
          );

          return (
            <div
              key={index}
              className={`${styles['product-card']} ${
                isSelected ? styles['selected-product'] : ''
              }`}
              onClick={() => handleProductClick(product)}
            >
              {/* Product Title */}
              <div className={styles['product-title']}>{product.name}</div>

              {/* Product Details */}
              <div className={styles['product-details']}>
                {/* Store Logo */}
                {product.store && product.store.logo && (
                  <img
                    src={product.store.logo}
                    alt={product.store.name}
                    className={styles['store-logo']}
                  />
                )}

                {/* Product Image */}
                <div className={styles['product-image-container']}>
                  <img
                    src={product.image}
                    alt={product.name}
                    className={styles['product-image']}
                  />
                </div>

                {/* Price */}
                <div className={styles['product-price']}>
                  {product.current_price?.toFixed(2)} kr
                </div>
              </div>
            </div>
          );
        })}
      </div>

      {/* Pagination Controls */}
      <div className={styles['pagination-controls']}>
        <button
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 1}
          className={styles['pagination-button']}
        >
          Forrige
        </button>
        <span className={styles['current-page-info']}>
          {currentPage} av {totalPages}
        </span>
        <button
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
          className={styles['pagination-button']}
        >
          Neste
        </button>
      </div>

      {/* Selected Products Grid */}
      {selectedProducts.length > 0 && (
        <div className={styles['selected-products-section']}>
          <h2 className={styles['section-title']}>Valgte Produkter</h2>
          <div className={styles['selected-products-grid']}>
            {selectedProducts.map((product, index) => (
              <div key={index} className={styles['selected-product-card']}>
                {/* Product Title */}
                <div className={styles['product-title']}>{product.name}</div>

                {/* Product Details */}
                <div className={styles['product-details']}>
                  {/* Store Logo */}
                  {product.store && product.store.logo && (
                    <img
                      src={product.store.logo}
                      alt={product.store.name}
                      className={styles['store-logo']}
                    />
                  )}

                  {/* Product Image */}
                  <div className={styles['product-image-container']}>
                    <img
                      src={product.image}
                      alt={product.name}
                      className={styles['product-image']}
                    />
                  </div>

                  {/* Price */}
                  <div className={styles['product-price']}>
                    {product.current_price?.toFixed(2)} kr
                  </div>
                </div>
              </div>
            ))}
          </div>

          {/* Total Price */}
          <div className={styles['total-price']}>
            <span>Total Pris: </span>
            <strong>{totalPrice.toFixed(2)} kr</strong>
          </div>
        </div>
      )}
    </div>
  );
};

export default ProductsView;
