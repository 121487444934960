import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { jwtDecode } from "jwt-decode";
import { supabase } from '../plugins/supabase';
import styles from '../modules/RecipeView.module.css';  
import placeholderImage from '../assets/landscape-placeholder.svg';

const backendUrl = process.env.REACT_APP_BACKEND_URL;

const RecipeView = () => {

  const navigate = useNavigate();
  
  const { id } = useParams();
  const [recipe, setRecipe] = useState(null);
  const [matchedProducts, setMatchedProducts] = useState({});
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isSaved, setIsSaved] = useState(false);
  const [popupProduct, setPopupProduct] = useState(null);
  const [popupIngredient, setPopupIngredient] = useState(null);

  const [token, setToken] = useState(null); 
  const [imageSrc, setImageSrc] = useState(placeholderImage);
  const [isImageLoaded, setIsImageLoaded] = useState(false);
  const [userId, setUserId] = useState(null);

  useEffect(() => {
    const getUser = async () => {
      const { data: { session } } = await supabase.auth.getSession();
      if (session) {
        setToken(session.access_token);
        setIsLoggedIn(true);
        
        const decodedToken = jwtDecode(session.access_token);
        setUserId(decodedToken.sub); 
      } else {
        setIsLoggedIn(false);
      }
    };
    getUser();
  }, []);
  

  useEffect(() => {
    const fetchRecipe = async () => {
      try {
        const response = await axios.get(`${backendUrl}/api/recipes/${id}?includeProductInfo=true`);
        setRecipe(response.data);
  
        if (isLoggedIn && token) {
          const isSavedResponse = await axios.get(`${backendUrl}/api/recipes/save/is-saved`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
            params: {
              recipeId: id,
            },
          });
          setIsSaved(isSavedResponse.data);
        }
  
        setError(null);
      } catch (err) {
        setError('Error fetching recipe details');
        console.error('Error fetching recipe details:', err);
      } finally {
        setLoading(false);
      }
    };
  
    fetchRecipe();
  }, [id, isLoggedIn, token]);
  
  

  useEffect(() => {
    const fetchRecipeImage = async (imageUrl) => {
      try {
        if (!imageUrl) {
          setImageSrc(placeholderImage);
          setIsImageLoaded(true);
          return;
        }
  
        const headers = token ? { Authorization: `Bearer ${token}` } : {};
        const imageResponse = await axios.get(imageUrl, { headers, responseType: 'blob' });
  
        const imageObjectUrl = URL.createObjectURL(imageResponse.data);
        setImageSrc(imageObjectUrl);
      } catch (err) {
        console.error('Error fetching image:', err);
        setImageSrc(placeholderImage);
      } finally {
        setIsImageLoaded(true);
      }
    };
  
    if (recipe?.recipe?.imageUrl) {
      fetchRecipeImage(recipe.recipe.imageUrl);
    } else {
      setImageSrc(placeholderImage);
      setIsImageLoaded(true);
    }
  }, [recipe?.recipe?.imageUrl, token]);
  
  
  

  const handleSaveRecipe = async () => {
    if (!isLoggedIn || !token) return;
  
    try {
      if (isSaved) {
        await axios.delete(`${backendUrl}/api/recipes/save/remove`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: {
            recipeId: id,
          },
        });
        setIsSaved(false);
      } else {
        await axios.post(
          `${backendUrl}/api/recipes/save`,
          null,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
            params: {
              recipeId: id,
            },
          }
        );
        setIsSaved(true);
      }
    } catch (err) {
      console.error('Error saving/removing recipe:', err);
    }
  };

  const handleDeleteClick = async () => {
    if (!userId || !token) return;

    try {
      await axios.delete(`${backendUrl}/api/recipes/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
        params: { userId: userId },
      });
      alert('Recipe deleted successfully');
      navigate('/'); 
    } catch (error) {
      console.error('Error deleting recipe:', error);
      alert('Error deleting the recipe.');
    }
  };
  

  const openPopup = (ingredientName) => {
    const product = matchedProducts[ingredientName]?.products || [];
    setPopupProduct(product);
    setPopupIngredient(ingredientName);
  };

  const closePopup = () => {
    setPopupProduct(null);
    setPopupIngredient(null);
  };

  const handlePopupProductClick = (ingredientName, product) => {
    setMatchedProducts(prev => {
      const newMatchedProducts = {
        ...prev,
        [ingredientName]: {
          ...prev[ingredientName],
          selected: product
        }
      };

      return newMatchedProducts;
    });
    closePopup();
  };

  const handleEditClick = () => {
    navigate(`/recipes/edit/${id}`);
  };

  if (loading || !isImageLoaded) {
    return <div className="text-center text-primary">Laster...</div>; 
  }

  if (error) {
    return <div className="text-red-500 text-center">{error}</div>;
  }
  return (
    <div className={styles["recipe-container"]}>
      {recipe ? (
        <div>
          <div>
            {userId === recipe.recipe.userId && (
              <div className={styles["edit-button-container"]}>
                <button onClick={handleEditClick} className={styles["edit-button"]}>
                  Endre oppskrift
                </button>
                <button onClick={handleDeleteClick} className={styles["delete-button"]}>
                  Slett
                </button>
              </div>
            )}
          </div>
          
          <div className={styles["recipe-top-container"]}>
            {/* Left section: Recipe Information */}
            <div className={styles["recipe-info-container"]}>
              {/* Recipe Tags at the Top */}
              <div className={styles["recipe-tags"]}>
                {recipe.recipe.tags && recipe.recipe.tags.length > 0 && (
                  <div className={styles["tags-container"]}>
                    {recipe.recipe.tags.map((tag, index) => (
                      <span key={index} className={styles["recipe-tag"]}>
                        {tag}
                      </span>
                    ))}
                  </div>
                )}
              </div>
  
              {/* Recipe Title and Description */}
              <div className={styles["recipe-text-container"]}>
                <h1 className={styles["recipe-title"]}>{recipe.recipe.name}</h1>
                <p className={styles["recipe-description"]}>{recipe.recipe.description}</p>
              </div>
  
              {/* Total Price */}
              <div className={styles["total-price-container"]}>
                <h3 className={styles["total-price"]}>{Math.round(recipe.recipe.storedPrice)} kr</h3>
              </div>
            </div>
  
            {/* Right section: Recipe Image */}
            {imageSrc && (
              <div className={styles["recipe-image-container"]}>
                {isLoggedIn && (
                  <button
                    className={`${styles["save-button"]} ${isSaved ? styles["saved"] : ''}`}
                    onClick={handleSaveRecipe}
                  >
                    <span className="material-icons" style={{ fontSize: '50px' }}>
                      {isSaved ? 'bookmark' : 'bookmark_border'}
                    </span>
                  </button>
                )}
                <img src={imageSrc} alt={recipe.recipe.name} className={styles["recipe-image"]} />
              </div>
            )}
          </div>
  
          {/* Ingredients Grid */}
          <div className={styles["ingredients-container"]}>
            <h3 className={styles["ingredients-title"]}>Ingredienser</h3>
            <div className={styles["ingredients-grid"]}>
              {recipe.recipe.ingredients && recipe.recipe.ingredients.length > 0 ? (
                recipe.recipe.ingredients.map((ingredient, index) => {
                  const productInfo = recipe.productInfo.find(product => product.ean === ingredient.ean);
  
                  return (
                    <div key={index} className={styles["ingredient-card"]} onClick={() => openPopup(ingredient.ean)}>
                      <div className={styles["ingredient-card-content"]}>
                        {/* Ingredient Info */}
                        <div className={styles["ingredient-info-left"]}>
                          <span className={styles["ingredient-text"]}>
                            {ingredient.name} {ingredient.amount} {ingredient.unit}
                          </span>
  
                          {/* Product Info */}
                          {productInfo && (
                            <>
                              <span className={styles["product-name"]}>{productInfo.name}</span>
                              <span className={styles["product-price"]}>{productInfo.current_price?.toFixed(2)} kr</span>
                            </>
                          )}
                        </div>
  
                        {/* Store Logo */}
                        {productInfo?.store?.logo && (
                          <img
                            src={productInfo.store.logo}
                            alt={productInfo.store.name}
                            className={styles["ingredient-store-logo"]}
                          />
                        )}
  
                        {/* Product Image */}
                        {productInfo && (
                          <img
                            src={productInfo.image}
                            alt={ingredient.name}
                            className={styles["ingredient-product-image"]}
                          />
                        )}
                      </div>
                    </div>
                  );
                })
              ) : (
                <div className={styles["no-ingredients"]}>Ingen ingredienser oppgitt.</div>
              )}
            </div>
          </div>
  
          {/* Recipe Instructions */}
          <div className={styles["recipe-instructions-container"]}>
            <h3 className={styles["recipe-instructions-title"]}>Instruksjon</h3>
            <p className={styles["recipe-instructions-text"]}>{recipe.recipe.instructions}</p>
          </div>
        </div>
      ) : (
        <p className={styles["no-recipe"]}>Oppskrift ikke funnet</p>
      )}
  
      {/* Product Selection Popup */}
      {popupProduct && (
        <div className={styles["popup-container"]}>
          <button className={styles["close-button"]} onClick={closePopup}>
            &times;
          </button>
          <div className={styles["popup-content"]}>
            <h3 className={styles["popup-title"]}>Velg ett produkt {popupIngredient}</h3>
            <div className={styles["popup-grid"]}>
              {popupProduct.map((product, index) => (
                <div
                  key={index}
                  className={styles["popup-product"]}
                  onClick={() => handlePopupProductClick(popupIngredient, product)}
                >
                  {/* Product Image */}
                  <img src={product.image} alt={product.name} className={styles["popup-product-image"]} />
                  <div className={styles["product-info"]}>
                    {/* Left Column for Name and Price */}
                    <div className={styles["product-info-left"]}>
                      <span className={styles["popup-product-name"]}>{product.name}</span>
                      <span className={styles["popup-product-price"]}>{product.current_price?.toFixed(2)} kr</span>
                    </div>
                    {/* Store Logo */}
                    {product.store && product.store.logo && (
                      <img src={product.store.logo} alt={product.store.name} className={styles["popup-store-logo"]} />
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );  
};

export default RecipeView;